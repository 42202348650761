import axios from 'axios';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
        'api_key': process.env.React_App_API_KEY,
    }
})


export default instance;