import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from '../../../../InstanceAxios/InstanceAxios';
import { makeStyles } from '@material-ui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    searchResultThana: {

        marginTop: '5px',
        minWidth: '564px',
        height: '200px',
        backgroundColor: 'white',

        overflow: 'hidden',
        overflowY: 'auto',
    },
    thanaItem: {
        width: '100%',
        height: '50px',
        '&:hover': {
            backgroundColor: '#e9ecef',
        },
    },
    resultBox: {
        boxShadow: '0px 5px 5px -3px',
        zIndex: 999,
        position: 'absolute'
    }


});

const getFormValues = () => {
    const storedValues = localStorage.getItem('form');
    if (!storedValues) {
        return {
            email: '',
            name: '',
            note: ''
        }
    };
    return JSON.parse(storedValues);

}

// const phoneRegex = /^((01)?[3-9]\d{9})$/;
const phoneRegex = /^0?1[3456789][0-9]{8}\b/g;

const CustomerInfoRetro = () => {

    const [values, setValues] = useState(getFormValues);
    const classes = useStyles();
    const [index, setIndex] = useState(0)

    const [thanas, setThanas] = useState([])
    const [searchedThana, setSearchedThana] = useState([])
    const [wordEntered, setWordEntered] = useState(values.thana)
    const [phone, setPhone] = useState(values.phone)

    const [error, setError] = useState(false)


    let district = values.city

    let shop = useSelector((state) => state?.product?.shop)

    useEffect(() => {
        localStorage.setItem('form', JSON.stringify(values));

    }, [values]);


    useEffect(() => {
        const divisionIndex = localStorage.getItem('divisionIndex')

        setIndex(divisionIndex)
    }, []);



    const handleChange = (e) => {

        setValues((previousValues) => ({
            ...previousValues,
            [e.target.name]: e.target.value,
            city: district,

        }));

    }



    const division = [
        { name: 'Barisal', city: ['Barguna', 'Barisal', 'Bhola', 'Jhalokati', 'Patuakhali', 'Pirojpur'] },
        { name: 'Chittagong', city: ['Bandarban', 'Brahmanbaria', 'Chandpur', 'Chittagong', 'Comilla', "Cox's Bazar", 'Feni', 'Khagrachhari', 'Lakshmipur', 'Noakhali', 'Rangamati'] },
        { name: 'Dhaka', city: ['Dhaka', 'Faridpur', 'Gazipur', 'Gopalganj', 'Kishoreganj', 'Madaripur', 'Manikganj', 'Munshiganj', 'Narayanganj', 'Narsingdi', 'Rajbari', 'Shariatpur', 'Tangail'] },
        { name: 'Khulna', city: ['Jessore', 'Jhenaidah', 'Khulna', 'Kushtia', 'Magura', 'Meherpur', 'Narail', 'Satkhira'] },
        { name: 'Mymensingh', city: ['Jamalpur', 'Mymensingh', 'Netrokona', 'Sherpur'] },
        { name: 'Rajshahi', city: ['Bogra', 'Joypurhat', 'Naogaon', 'Natore', 'Chapai Nawabganj', 'Pabna', 'Rajshahi', 'Sirajganj'] },
        { name: 'Rangpur', city: ['Dinajpur', 'Gaibandha', 'Kurigram', 'Lalmonirhat', 'Nilphamari', 'Panchagarh', 'Rangpur', 'Thakurgaon'] },
        { name: 'Sylhet', city: ['Habiganj', 'Moulvibazar', 'Sunamganj', 'Sylhet'] }
    ]



    const handleClick = (index) => {
        setIndex(index)
        localStorage.setItem('divisionIndex', index)
        district = ''

    }


    //thana
    const url = `/locations/getThanas`
    useEffect(() => {


        const fetchData = async () => {
            const response = await axios.get(url)
            setThanas(response.data.thanas)
        }
        fetchData();

    }, [])

    // console.log(thanas);

    const handleChangeThana = e => {

        const searchWord = e.target.value
        setWordEntered(searchWord)

        const newFilter = thanas.filter((value) => {

            return value.title.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === '') {
            setSearchedThana([])
        } else {
            setSearchedThana(newFilter)
        }

    }

    const handleSelectThana = e => {
        setSearchedThana([])
        setWordEntered(e)
        setValues((previousValues) => ({
            ...previousValues,
            thana: e

        }));
    }

    const clearInput = () => {
        console.log('click');
        setSearchedThana([])
        setWordEntered('')
    }


    //phone number validation

    const phoneNumberValidation = e => {


        if (phoneRegex.test(e.target.value) == true) {
            console.log(e.target.value);
            console.log(phoneRegex.test(e.target.value));
            setPhone(e.target.value)
            setError(false);
            setValues((previousValues) => ({
                ...previousValues,
                phone: e.target.value

            }));

        } else {
            console.log(e.target.value);
            console.log(phoneRegex.test(e.target.value));
            setError(true);
            setPhone(e.target.value)
        }
    }

    if (shop?.facebook_pixel_id) {
        ReactPixel.pageView();
    }
    if (shop?.google_analytics_measurement_id) {
        ReactGA.send("pageview");
    }
    // console.log(thana);

    return (
        <Container>
            <Typography sx={{ fontWeight: '500', fontSize: '22px', textAlign: 'left', marginBottom: '20px', fontFamily: 'Quicksand' }}>Your Information</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Name"
                        name='name'
                        type="text"
                        placeholder="Enter Your Name"
                        value={values.name}
                        // onChange={e => setName(e.target.value)}
                        onChange={handleChange}
                        InputLabelProps={{ sx: { fontFamily: 'Quicksand' } }}
                        InputProps={{ sx: { fontFamily: 'Quicksand', borderRadius: '0px' } }}

                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Phone"
                        name='phone'
                        type=""
                        placeholder="Enter Your Number"
                        value={phone}
                        error={error}
                        helperText={error ? 'Invalid Number' : ''}
                        onChange={phoneNumberValidation}
                        InputLabelProps={{ sx: { fontFamily: 'Quicksand' } }}
                        InputProps={{ sx: { fontFamily: 'Quicksand', borderRadius: '0px' } }}

                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField

                        fullWidth
                        id="outlined"
                        label="Email"
                        name='email'
                        type="email"
                        placeholder="Enter Your Email"
                        value={values.email}
                        // onChange={e => setName(e.target.value)}
                        onChange={handleChange}

                        InputLabelProps={{ sx: { fontFamily: 'Quicksand' } }}
                        InputProps={{ sx: { fontFamily: 'Quicksand', borderRadius: '0px' } }}

                    />
                </Grid>


                <Grid item xs={12} md={6}>
                    <FormControl required fullWidth>
                        <InputLabel id="demo-simple-select-label">Division</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.division}
                            name='division'
                            label="Division"
                            onChange={handleChange}
                            sx={{ textAlign: 'left', fontFamily: 'Quicksand', borderRadius: '0px' }}>


                            {division.map((c, index) => (
                                <MenuItem sx={{ fontFamily: 'Quicksand' }} onClick={() => handleClick(index)} key={c.name} value={c.name}>
                                    {c.name}
                                </MenuItem>
                            ))}


                        </Select>
                    </FormControl>


                </Grid>




                <Grid item xs={12} md={12}>
                    <TextField

                        fullWidth
                        required
                        id="outlined-required"

                        label="Address"
                        name='address'
                        type="text"
                        value={values.address}
                        // onChange={e => setName(e.target.value)}
                        onChange={handleChange}
                        InputLabelProps={{ sx: { fontFamily: 'Quicksand' } }}
                        InputProps={{ sx: { fontFamily: 'Quicksand', borderRadius: '0px' } }}


                    />
                </Grid>



                <Grid item xs={12} md={6}>
                    <FormControl required fullWidth sx={{ borderRadius: '0px' }}>
                        <InputLabel id="demo-simple-select-label">Town/City/District</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.city}
                            name='city'
                            label="Town/City/District"
                            onChange={handleChange}
                            sx={{ textAlign: 'left', fontFamily: 'Quicksand', borderRadius: '0px' }}
                        >

                            {division[index]?.city.map((c) => (
                                <MenuItem sx={{ fontFamily: 'Quicksand' }} key={c} value={c} onClick={() => district = c}>
                                    {c}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>


                </Grid>



                {/* thana */}

                <Grid item xs={12} md={6} >

                    {/* <input /> */}

                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Thana"
                        value={wordEntered}
                        name='thana'
                        type="text"
                        placeholder="Thana"

                        // onChange={e => setName(e.target.value)}
                        onChange={handleChangeThana}
                        InputLabelProps={{ sx: { fontFamily: 'Quicksand' } }}
                        InputProps={{ sx: { fontFamily: 'Quicksand', borderRadius: '0px' } }}

                    />


                    <Box className={classes.resultBox}>
                        {
                            searchedThana.length != 0 && (
                                <div className={classes.searchResultThana}>
                                    {
                                        searchedThana.map((search, key) =>
                                            <p className={classes.thanaItem} onClick={() => { handleSelectThana(search.title) }}>{search.title}</p>)
                                    }
                                </div>

                            )

                        }

                    </Box>

                </Grid>


                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="outlined-required"
                        label="Add A Note"
                        name='note'
                        type="text"
                        placeholder="e.g Special Request, additional information"
                        value={values.note}
                        // onChange={e => setEmail(e.target.value)}
                        onChange={handleChange}
                        InputLabelProps={{ sx: { fontFamily: 'Quicksand' } }}
                        InputProps={{ sx: { fontFamily: 'Quicksand', borderRadius: '0px' } }}

                    />



                </Grid>


            </Grid>
        </Container >
    );
};

export default CustomerInfoRetro;