
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import cancel from '../../../../images/logo/cancel.gif';
import NavBarRetro from '../../SharedRetro/NavBarRetro/NavBarRetro';
// import { fetchShop } from '../../../../redux/slices/productSlice';


const CancelledRetro = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const shopLink = params?.shop_link

    // console.log(shopLink);


    // let shop = JSON.parse(localStorage.getItem('shop'));


    const name = process.env.React_App_NAME;
    const hostName = window.location.hostname;


    // useEffect(() => {
    //     // console.log('shopLink', shopLink);
    //     hostName == name ?
    //         (shop?.shop_link !== shopLink && dispatch(fetchShop(shopLink)))
    //         : (shop?.shop_domain !== hostName && dispatch(fetchShop(hostName)))

    // }, [dispatch])
    let shop = useSelector((state) => state?.product?.shop)



    const handleClick = () => {
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate(`/`)
    }


    const ColorButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #000000',
        backgroundColor: '#000000',
        fontSize: '14px',
        fontWeight: 'normal',
        height: '45px',
        width: '169px',
        color: '#FFFFFF',
        marginTop: '40px',
        fontFamily: 'Quicksand',
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#000000'
        },

    }));

    return (
        <>
            <NavBarRetro></NavBarRetro>



            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: '50%', transform: ' translate(0, 50%)' }}>
                <img style={{ width: '150px', height: '150px' }} src={cancel} />
                <Typography sx={{ color: '#021F3F', fontSize: '24px', fontFamily: 'Montserrat' }}>Your payment wasn't completed</Typography>
                <Typography sx={{ color: '#95989A', fontSize: '16px', fontFamily: 'Montserrat' }}>Please complete payment to complete your order.</Typography>
                {/* <Typography sx={{ color: '#95989A', fontSize: '16px', fontFamily: 'Montserrat' }}>the marchent accept the order</Typography> */}

                <ColorButton
                    size="small"
                    onClick={handleClick}

                >Continue Shopping

                </ColorButton>
            </Box>

        </>
    );
};

export default CancelledRetro;