let productDetail = {}
const cartProduct = (details) => {

    let tempSelect = []
    for (let i = 0; i < details?.variant_names?.length; i++) {
        tempSelect.push({
            'title': details?.variant_names[i]?.title,
            'value': details?.variant_products[0]?.variant_value_variant_product[i]?.v_value_id,
            'vTitle': details?.variant_names[i]?.variant_values[0].title

        })
    }

    details?.stock && (
        productDetail.shop_id = details?.shop_id,
        productDetail.campaign_id = details?.campaign_id,
        productDetail.product_id = details?.product_id,
        productDetail.product_name = details?.product_name,
        productDetail.product_images = details?.product_images

    )

    details?.variant_products && (details?.variant_products[0] ? (
        productDetail.unit_selling_price = details?.variant_products[0]?.selling_price,
        productDetail.stock = details?.variant_products[0]?.in_stock,
        productDetail.variant_product_id = details?.variant_products[0]?.id,
        productDetail.unit_discounted_price = details?.variant_products[0]?.unit_discounted_price,
        productDetail.select = tempSelect,

        productDetail.amount_off = details?.variant_products[0]?.amount_off,
        productDetail.percentage_off = details?.variant_products[0]?.percentage_off
    ) : (
        productDetail.unit_selling_price = details?.unit_selling_price,
        productDetail.stock = details?.stock[0]?.number_of_stocks,
        productDetail.variant_product_id = null,
        productDetail.unit_discounted_price = details?.unit_discounted_price,

        productDetail.amount_off = details?.amount_off,
        productDetail.percentage_off = details?.percentage_off
    ))
    return productDetail


}

const updateCart = (price, stock, id, select, discountPrice) => {
    productDetail.unit_selling_price = price,
        productDetail.variant_product_id = id,
        productDetail.stock = stock,
        productDetail.select = select,
        productDetail.unit_discounted_price = discountPrice?.unit_discounted_price

    return productDetail
}
export { cartProduct, updateCart }