import React from 'react';
import { Button, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';

import { removeFromCart } from '../../../../redux/slices/cartSlice';
import { removeFromDb } from '../../../../utilities/fakeDb';
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';

import emptyBag from '../../../../images/logo/Techne/TechneBag.jpg';
import NavBarRetro from '../../SharedRetro/NavBarRetro/NavBarRetro';
import AddToBagResponsiveRetro from '../../SharedRetro/Buttons/AddToBagResponsiveRetro/AddToBagResponsiveRetro';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";



const BagResponSiveRetro = () => {
    const navigate = useNavigate()
    const params = useParams();
    const dispatch = useDispatch();

    // let shop = JSON.parse(localStorage.getItem('shop'));
    const shopLink = params?.shop_link

    const name = process.env.React_App_NAME;


    const hostName = window.location.hostname;


    let shop = useSelector((state) => state?.product?.shop)


    const cart = useSelector((state) => state?.cart)
    const cartItems = cart?.cartItems;

    const carts = cartItems?.filter(cartItem => cartItem?.shop_id === shop?.shop_id)



    const handleDeleteProduct = (product) => {


        removeFromDb(product.product_id)
        dispatch(removeFromCart(product))

    }

    let total = 0;
    let totalSellingPrice = 0;

    carts.map(cart => {
        let itemTotal;
        const { unit_selling_price, quantity, unit_discounted_price } = cart;
        const itemTotalSellingPrice = unit_selling_price * quantity;
        unit_discounted_price ? (itemTotal = unit_discounted_price * quantity) : (itemTotal = unit_selling_price * quantity)

        totalSellingPrice += itemTotalSellingPrice
        total += itemTotal

    })
    // console.log(total);
    // console.log(totalSellingPrice);

    const handleClick = () => {
        navigate(-1)
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #000000',

        fontSize: '3.74vw',
        fontWeight: 'normal',
        height: '45px',
        width: '100%',
        color: '#000000',

    }));
    const ColorButtonEmptyBag = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #000000',

        fontSize: '3.74vw',
        fontFamily: 'Quicksand',
        fontWeight: 'normal',
        height: '45px',
        width: '100%',
        color: '#FFFFFF',
        backgroundColor: '#000000',

    }));

    const handleClickCheckOutButton = () => {
        carts?.length == 0 ? (
            // dispatch(addToCart(product))
            alert('please add a product')
        ) : (hostName == name ? navigate(`/${shop?.shop_link}/bagInfo`) : navigate(`/bagInfo`))

    }

    const CheckOutButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #000000',

        objectFit: 'cover',
        width: '100%',
        fontSize: '14px',
        fontWeight: 'normal',
        height: '32px',
        backgroundColor: '#000000',
        height: '45px',
        color: '#FFFFFF',

    }));

    if (shop?.facebook_pixel_id) {
        ReactPixel.pageView();
    }
    if (shop?.google_analytics_measurement_id) {
        ReactGA.send("pageview");
    }

    return (
        <>
            <NavBarRetro />
            {
                carts[0] ? (<Box>
                    <Box sx={{ marginBottom: '149px', marginTop: '56px' }}>
                        {
                            carts?.map(cart => <Card key={cart.product_id} sx={{ height: 'auto', width: 'auto', maxHeight: '100', aspectRatio: '3/1', display: 'flex', justifyContent: 'space-between', marginBottom: '14px', marginX: '16px', boxShadow: 'none', border: '1px solid #E6E6E6', }}>

                                <Box sx={{ display: 'flex' }}>

                                    <CardMedia
                                        component="img"
                                        sx={{ width: '100%', height: '100%', aspectRatio: '1/1' }}
                                        image={cart?.product_images[0]?.image_url}
                                        alt=""
                                    />

                                    {/* <CardContent> */}

                                    {/* <Box sx={{ display: 'flex' }}> */}

                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '12px' }}>

                                        <Typography sx={{ fontSize: '4.26vw', fontWeight: '500', textAlign: 'left', width: '42.667vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#000000' }}>{cart.product_name}</Typography>
                                        <Box sx={{ textAlign: 'left' }}>
                                            {
                                                cart.unit_discounted_price ?
                                                    (
                                                        <>
                                                            <del style={{ color: '#95989A', fontSize: '3.74vw' }}>

                                                                {shop?.currencies?.title}  {cart.unit_selling_price}
                                                            </del>
                                                            <Typography sx={{ fontSize: '4.26vw', fontWeight: '500', color: '#000000' }}>
                                                                {shop?.currencies?.title}  {cart.unit_discounted_price}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <br />
                                                            <Typography sx={{ fontSize: '4.26vw', fontWeight: '500', color: '#000000' }}>
                                                                {shop?.currencies?.title}  {cart.unit_selling_price}
                                                            </Typography>
                                                        </>
                                                    )
                                            }
                                        </Box>

                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center', marginRight: '16px' }}>
                                    <DeleteIcon sx={{ color: '#000000', marginBottom: '13px', width: '7vw' }} onClick={() => handleDeleteProduct(cart)} />
                                    <AddToBagResponsiveRetro product={cart}></AddToBagResponsiveRetro>
                                </Box>

                                {/* </Box> */}
                                {/* </CardContent> */}
                            </Card>)
                        }
                    </Box>
                    <Box sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#FFFFFF' }}>
                        <hr />
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '20px', color: '#000000' }}>
                            <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} >
                                <Grid item xs={12} sx={{ color: '#000000', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Total</Typography>

                                    {/* <Typography sx={{ fontSize: '16px', marginLeft: '80px', fontWeight: 500 }}>{total + totalVat}</Typography> */}
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                                        {shop?.currencies?.title}  {total}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ marginTop: '12px' }}>
                                <Grid item xs={6}>
                                    <ColorButton
                                        size="small"
                                        onClick={handleClick}

                                    >Continue Shopping

                                    </ColorButton>
                                </Grid>
                                <Grid item xs={6}>

                                    <CheckOutButton
                                        size="small"
                                        onClick={handleClickCheckOutButton}

                                    >Checkout

                                    </CheckOutButton>

                                </Grid>
                            </Grid>

                        </Container>
                    </Box>
                </Box>) : (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '50%' }}>
                    <img style={{ height: '180px', width: '120px', marginBottom: '32px' }} src={emptyBag} alt="" />
                    <Typography sx={{ fontWeight: '400', fontSize: '5.867vw', color: '#000000', fontFamily: 'Quicksand' }}>Oops! Your bag is empty </Typography>
                    <Typography sx={{ fontWeight: '400', fontSize: '4.267vw', color: '#95989A', fontFamily: 'Quicksand' }}>but it doesn't have to be</Typography>
                    <Grid container spacing={1} sx={{ marginTop: '64px' }}>
                        <Grid item xs={3}>

                        </Grid>
                        <Grid item xs={6}>
                            <ColorButtonEmptyBag
                                size="small"
                                onClick={handleClick}

                            >Continue Shopping

                            </ColorButtonEmptyBag>
                        </Grid>
                        <Grid item xs={3}>

                        </Grid>

                    </Grid>
                </Box>)
            }

        </>
    );
};

export default BagResponSiveRetro;