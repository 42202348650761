

const addToDb = id => {
    // console.log(id);
    const exists = getDb('shopping_cart');
    let shopping_cart = {};

    if (!exists) {
        shopping_cart[id] = 1;
    }
    else {
        shopping_cart = JSON.parse(exists);
        // console.log('shop', shopping_cart);
        if (shopping_cart[id]) {
            const newCount = shopping_cart[id] + 1;
            shopping_cart[id] = newCount;
        }
        else {
            shopping_cart[id] = 1;
        }
    }
    updateDb(shopping_cart);
}

const subtractFromDb = id => {

    const exists = getDb('shopping_cart');
    let shopping_cart = {};

    shopping_cart = JSON.parse(exists);

    const newCount = shopping_cart[id] - 1;
    shopping_cart[id] = newCount;

    updateDb(shopping_cart);
}


const getDb = dbName => {
    return localStorage.getItem(dbName);

}

const getStoredCart = (dbName) => {
    const exists = getDb(dbName);
    return exists ? JSON.parse(exists) : [];
}


const updateDb = cart => {
    localStorage.setItem('cart', JSON.stringify(cart));
}

const removeFromDb = id => {
    const exists = getDb('cart');
    if (!exists) {

    }
    else {
        const shopping_cart = JSON.parse(exists);
        // console.log(shopping_cart);
        const items = shopping_cart.filter(product => product.product_id !== id)
        // console.log(items);
        // delete shopping_cart[deleteShoppingCart];
        updateDb(items);
    }
}

const clearDb = (dbName) => {
    localStorage.removeItem(dbName);
}



export { addToDb, subtractFromDb, getDb, getStoredCart, updateDb, removeFromDb, clearDb };