
import { Button, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToBagButton from '../../Shared/Button/AddToBagButton/AddToBagButton';
import useProducts from '../../../../hooks/useProducts';
import { getTotals, removeFromCart } from '../../../../redux/slices/cartSlice';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/system';
import { removeFromDb } from '../../../../utilities/fakeDb';
import emptyBag from '../../../../images/logo/emptybag.png'



const useStyles = makeStyles({
    
});


const BagProduct = ({ carts }) => {
    // console.log('cart bag product', cart);

    const classes = useStyles();


    const dispatch = useDispatch()
    const shop = useSelector((state) => state?.product?.shop)

    const handleDeleteProduct = (product) => {
        // console.log('bagproduct', product.product_id);

        removeFromDb(product.product_id)
        dispatch(removeFromCart(product))

    }
    useEffect(() => {
        dispatch(getTotals())
    }, [dispatch])


    // console.log(total);
    // console.log(totalSellingPrice);




    const ColorButtonEmptyBag = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #FF6550',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 'normal',
        height: '45px',
        width: '100%',
        color: '#FFFFFF',
        backgroundColor: '#FF6550',



    }));





    return (
        <>
            {
                carts[0] ? (<Box sx={{ maxWidth: '445px' }} >
                    <Box sx={{ marginBottom: '149px', marginLeft: '3.5vw' }}>
                        {
                            carts?.map(cart => <Card key={cart.product_id} sx={{ height: ' 100%', width: '100%', maxHeight: '120px', aspectRatio: '1/4', maxWidth: '488px', display: 'flex', justifyContent: 'space-between', marginBottom: '14px', boxShadow: 'none', border: '1px solid #E6E6E6', borderRadius: '4px', marginTop: '24px' }}>

                                <Box sx={{ display: 'flex' }}>

                                    <CardMedia
                                        component="img"
                                        sx={{ width: '100%', height: '100%', aspectRatio: '1/1' }}
                                        image={cart?.product_images[0]?.image_url}
                                        alt=""
                                    />

                                    {/* <CardContent> */}

                                    {/* <Box sx={{ display: 'flex' }}> */}

                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '12px' }}>

                                        <Typography sx={{ fontSize: '16px', fontWeight: '500', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#021F3F', width: '14vw', fontFamily: 'Montserrat' }}>{cart.product_name}</Typography>
                                        <Typography sx={{ fontWeight: 500, fontSize: '12px', lineHeight: '16px', color: '#79747E', textAlign: 'left', marginBottom: '12px', fontFamily: 'Montserrat' }}>{cart?.stock} piece(s) in stock</Typography>
                                        <Box sx={{ textAlign: 'left' }}>
                                            {
                                                cart.unit_discounted_price ? (<><del style={{ color: '#95989A', fontSize: '12px', fontFamily: 'Montserrat' }}>
                                                    {shop?.currencies?.title}  {cart.unit_selling_price}</del>
                                                    <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#021F3F', fontFamily: 'Montserrat' }}>
                                                        {shop?.currencies?.title}  {cart.unit_discounted_price}
                                                    </Typography></>) :
                                                    (<><Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#021F3F', fontFamily: 'Montserrat' }}>
                                                        {shop?.currencies?.title}  {cart.unit_selling_price}
                                                    </Typography></>)
                                            }
                                        </Box>

                                    </Box>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center', marginRight: '16px' }}>
                                    <DeleteIcon sx={{ color: '#FF6550', marginBottom: '13px', width: '24px' }} onClick={() => handleDeleteProduct(cart)} />
                                    <AddToBagButton product={cart}></AddToBagButton>
                                </Box>

                                {/* </Box> */}
                                {/* </CardContent> */}
                            </Card>)
                        }
                    </Box>

                </Box >) : (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '25%' }}>
                    <img style={{ height: '180px', width: '120px', marginBottom: '20px' }} src={emptyBag} alt="" />
                    <Typography sx={{ fontWeight: '400', fontSize: '22px', color: '#021F3F', fontFamily: 'Montserrat' }}>Oops! Your bag is empty </Typography>
                    <Typography sx={{ fontWeight: '400', fontSize: '16px', color: '#95989A', fontFamily: 'Montserrat' }}>but it doesn't have to be</Typography>

                </Box>)
            }
        </>

    );
};

export default BagProduct;