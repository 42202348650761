import { Avatar, Box, Button, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BagTechne from '../../BagTechne/BagTechne/BagTechne';

const NavBarTechne = () => {


    let shop = useSelector((state) => state?.product?.shop)


    const navigate = useNavigate();
    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;




    const handleClickCollections = () => {
        // console.log('clicked');
        hostName == name ? navigate(`/${shop?.shop_link}/collections`) : navigate(`/collections`)
    }

    const handleClickTitle = () => {
        hostName == name ? navigate(`/${shop?.shop_link}`) : navigate('/')
    }

    const handleMenuClick = () => {
        hostName == name ? navigate(`/${shop?.shop_link}/menu`) : navigate(`/menu`)
    }


    return (
        <>
            <Box
                sx={{ backgroundColor: '#FFFFFF', position: 'fixed', top: 0, zIndex: 999, width: '100%' }}
            >
                <Container>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', margin: '20px 0' }}>

                        <Box sx={{ display: 'flex' }}>

                            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: '14px', ml: '150px', display: { xs: 'none', md: 'flex', } }}> */}
                            <IconButton sx={{ p: 0, mr: '16px', border: '1px solid #000000', display: { xs: 'none', md: 'flex' } }}>
                                <Avatar alt="Shop Name" src={shop?.shop_image} />

                            </IconButton>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                onClick={handleClickTitle}
                                sx={{ mr: 2, color: '#000000', cursor: 'pointer', fontSize: '24px', display: { md: 'block', xs: 'none' }, fontFamily: 'Quicksand' }}
                            >
                                {shop?.shop_name}
                            </Typography>
                        </Box>

                        <Stack direction="row" alignItems="center" spacing={4}>
                            {/* <NavLink to='/bag' style={{ textDecoration: 'none' }}> */}


                            <Button sx={{ textTransform: 'none', color: '#000000', fontSize: '16px', fontWidth: 500, lineHeight: '24px', fontFamily: 'Quicksand' }} onClick={handleClickCollections}>Collections</Button>


                            <BagTechne></BagTechne>


                        </Stack>

                    </Box>
                </Container>
            </Box>
            {/* manueIcon */}
            <Container maxWidth="xl" sx={{ backgroundColor: '#FFFFFF', position: 'fixed', top: 0, zIndex: 999 }}>
                <Box sx={{ display: { xs: 'flex', md: 'none' }, paddingRight: '35px' }}>
                    <Grid container >
                        <Grid item xs={10} >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                    // onClick={handleOpenNavMenu}
                                    sx={{ paddingLeft: '0px' }}
                                    color="inherit"
                                >
                                    <MenuIcon sx={{ color: '#000000', width: '6.667vw', height: '5.333vw' }} />
                                </IconButton>

                                <Box sx={{ width: '49.600vw', height: '56px', display: 'flex', alignItems: 'center' }}>

                                    {/* LogoRes */}

                                    <Typography
                                        variant="h6"
                                        onClick={handleClickTitle}
                                        component="div"
                                        sx={{ color: '#000000', fontSize: '4.8vw', fontFamily: 'Quicksand' }}
                                    >
                                        {shop?.shop_name}
                                    </Typography>
                                </Box>

                            </Box>

                        </Grid>
                        {/* <Grid item xs={0}>

                        </Grid> */}
                        <Grid item xs={2} >
                            <Grid container sx={{ display: 'flex', alignItems: 'center', height: '56px' }}>

                                <Grid item xs={6}>
                                    {/* <img style={{ color: '#FFFFFF', width: '6.667vw', height: '6.667vw' }} src={search} alt="" /> */}
                                </Grid>
                                <Grid item xs={6}>



                                    <BagTechne></BagTechne>


                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Box>

            </Container>
        </>
    );
};

export default NavBarTechne;