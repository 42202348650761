import Button from '@mui/material/Button';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import {
    Box, Container, FormControl, Grid, InputLabel, MenuItem,
    Select, Step, StepButton, Stepper, Typography, Backdrop, CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStoredCart } from '../../../../utilities/fakeDb';
import { fetchShop } from '../../../../redux/slices/productSlice';
import { clearCart, getTotals } from '../../../../redux/slices/cartSlice';
import axios from '../../../../InstanceAxios/InstanceAxios';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

const BagInfoSecStepRetro = () => {

    const [formData, setFormData] = useState({})
    // const [giftFormData, setGiftFormData] = useState({})
    const [deliveryCharge, setDeliveryCharge] = useState({});
    const [disable, setDisable] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    // const [values, setValues] = useState({});
    const params = useParams();
    const name = process.env.React_App_NAME;
    const activeStep = 1
    const steps = ['Customer Details', 'Order & Payment'];
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStep, setLoadingStep] = useState(1);
    const [shopPaymentMethods, setShopPaymentMethods] = useState([]);

    const dispatch = useDispatch();
    const cart = useSelector((state) => state?.cart)
    const shopLink = params?.shop_link
    let shop = JSON.parse(localStorage.getItem('shop'));
    const hostName = window.location.hostname;

    const urlDelivery = `/sales/getDeliveryCharge`;
    const urlShopPaymentMethods = `/paymentMethod/getAllPaymentMethodsByShop/${shop.shop_id}`

    const getShopPaymentMethods = async () => {
        const response = await axios.get(urlShopPaymentMethods);
        setShopPaymentMethods(response.data);
    }

    useEffect(() => {
        getShopPaymentMethods();
        const form = getStoredCart('form');
        setFormData(form);

        const deliveryChargeCalculation = async () => {
            let data = {}
            const form = JSON.parse(localStorage.getItem('form'))
            const thana = localStorage.getItem('thana')
            data.customer_district = form.city,
                data.customer_thana = thana,
                data.customer_division = form.division,
                data.items = items
            data.shop_id = shop.shop_id
            const response = await axios.post(urlDelivery, data);
            setDeliveryCharge(response.data);
        }
        deliveryChargeCalculation();
    }, []);


    useEffect(() => {
        hostName == name ?
            (shop?.shop_link !== shopLink && dispatch(fetchShop(shopLink)))
            : (shop?.shop_domain !== hostName && dispatch(fetchShop(hostName)))


    }, [dispatch])
    shop = useSelector((state) => state?.product?.shop)


    const cartItems = cart?.cartItems;
    const carts = cartItems?.filter(cartItem => cartItem?.shop_id === shop?.shop_id)

    useEffect(() => {
        dispatch(getTotals());
    }, [cart, dispatch])

    const handleChange = e => {
        // setValues(e.target.value)
        setSelectedPayment(e.target.value);
        setPaymentMethod(e.target.value);
    }

    const items = carts.map(cart => {
        return {
            campaign_id: cart.campaign_id,
            product_id: cart.product_id,
            variant_product_id: cart.variant_product_id,
            product_name: cart.product_name,
            quantity: cart.quantity,
            unit_selling_price: cart.unit_selling_price
        }
    })

    let total = 0;
    let totalSellingPrice = 0;

    carts.map(cart => {
        let itemTotal;
        const { unit_selling_price, quantity, unit_discounted_price } = cart;
        const itemTotalSellingPrice = unit_selling_price * quantity;
        unit_discounted_price ? (itemTotal = unit_discounted_price * quantity) : (itemTotal = unit_selling_price * quantity)

        totalSellingPrice += itemTotalSellingPrice
        total += itemTotal

    })

    const totalWithDelivery = total + parseInt(deliveryCharge?.delivery_fees);

    const url = '/sales/addSaleV4';
    const paymentInitUrl = '/sslcommerz/payment-init';

    const handleProceed = async (tran_id = null) => {
        setIsLoading(true);
        setDisable(true);
        let data = {}
        data.customer_phone_number = parseInt(formData.phone),
            data.customer_name = formData.name,
            data.customer_email = formData.email,
            data.customer_district = formData.city,
            data.customer_address = formData.address,
            data.customer_thana = formData.thana,
            data.customer_division = formData.division,
            data.customer_zipcode = '0',
            data.items = items
        data.shop_id = carts[0].shop_id,
            // data.total_amount = total + totalVat,
            data.total_amount = totalWithDelivery,
            data.received_amount = tran_id ? totalWithDelivery : 0,
            data.payment_method = "";
        data.payment_method_id = tran_id ? 3 : paymentMethod;
        data.delivery_partner = deliveryCharge?.delivery_partner?.id
        data.customer_note = formData.note
        data.sslcommerz_tran_id = tran_id
        const res = await axios.post(url, data);
        setIsLoading(false);
        res.data.status == 'S103' ? (hostName == name ? navigate(`/${shop?.shop_link}/successful`) : navigate(`/successful`), dispatch(clearCart())) : '';
    }


    const ProceedButton = styled(Button)(({ theme }) => ({
        ":disabled": { disable },
        textTransform: 'none',
        width: '100%',
        height: '56px',

        marginBottom: '80px',
        marginTop: '60px',
        fontSize: '22px',
        fontWeight: 'normal',
        backgroundColor: '#000000',
        borderColor: '#000000',
        fontFamily: 'Quicksand',
        '&:hover': {
            backgroundColor: '#000000',
        },
    }));

    const handleOnlinePayment = async () => {
        setIsLoading(true);
        setDisable(true);
        const shopData = JSON.parse(localStorage.getItem('shop'));
        const cartData = JSON.parse(localStorage.getItem('cart'));
        const data = {
            total_amount: totalWithDelivery,
            cus_name: formData.name,
            cus_email: formData.email,
            cus_phone: formData.phone,
            product_name: cartData[0].product_name,
            product_category: cartData[0].product_category.category,
            product_profile: "general",
            shop_link: shopData.shop_link
        };
        const response = await axios.post(paymentInitUrl, data);
        setIsLoading(false);
        window.location.replace(`${response.data.paymentLink}`);
    }

    const handleConfirmOrder = () => {
        if (paymentMethod) {
            if (carts[0]) {
                if (paymentMethod == 1) {
                    handleProceed();
                } else if (paymentMethod == 3) {
                    handleOnlinePayment();
                }
            } else {
                alert('Your bag is empty. Please add a product in your bag');
            }
        } else {
            setSelectedPayment(null);
        }
    }

    useEffect(() => {
        if (formData.hasOwnProperty("name") && deliveryCharge.hasOwnProperty("delivery_fees")) {
            if ((searchParams.get('status') == "successful") && searchParams.get('tran_id')) {
                setPaymentMethod(3);
                setSelectedPayment(3);
                setLoadingStep(2);
                handleProceed(searchParams.get('tran_id'));
            }
        }
    }, [formData, deliveryCharge]);

    if (shop?.facebook_pixel_id) {
        ReactPixel.pageView();
    }
    if (shop?.google_analytics_measurement_id) {
        ReactGA.send("pageview");
    }


    return (
        <Container>
            <Backdrop
                sx={{
                    color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,
                    display: 'flex', flexDirection: 'column'
                }}
                open={isLoading}
            >
                {/* <CircularProgress color="#000000" /> */}
                {
                    loadingStep == 1 ?
                        <Typography sx={{ mt: 10 }}>Payment initiating...</Typography> :
                        <Typography sx={{ mt: 10 }}>Your order is processing...</Typography>
                }
                <Typography>Do not close the browser</Typography>
            </Backdrop>

            <Box display={{ xs: 'none', md: 'block' }}>
                <Stepper nonLinear activeStep={activeStep} sx={{ width: '53%', marginX: '250px', marginTop: '100px', marginBottom: '76px', fontFamily: 'Quicksand' }} display={{ xs: 'none', md: 'block' }}>
                    {steps.map((label, index) => (
                        <Step sx={{ fontFamily: 'Quicksand' }} key={label} >
                            <StepButton sx={{ fontFamily: 'Quicksand' }} color="inherit">
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <Box display={{ xs: 'block', md: 'none' }}>
                <Stepper nonLinear activeStep={activeStep} sx={{ width: '90%', marginX: '22px', marginTop: '70px', marginBottom: '25px' }}>
                    {steps.map((label, index) => (
                        <Step key={label} >
                            <StepButton color="inherit">
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <Grid container spacing={2} >
                <Grid item xs={0} md={6} sx={{ color: '#021F3F' }} display={{ xs: 'none', md: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '60px' }}>
                        <Typography sx={{ fontSize: '22px', fontWeight: '500', marginBottom: '30px', fontFamily: 'Quicksand' }}>Customer Information</Typography>

                        <Typography sx={{ fontSize: '16px', fontFamily: 'Quicksand' }}><span style={{ fontWeight: '500', fontFamily: 'Quicksand' }}>Name: </span>{formData.name}</Typography>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'Quicksand' }}><span style={{ fontWeight: '500', fontFamily: 'Quicksand' }}>Phone: </span>{formData.phone}</Typography>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'Quicksand' }}><span style={{ fontWeight: '500', fontFamily: 'Quicksand' }}>Address: </span>{formData.address}</Typography>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'Quicksand' }}><span style={{ fontWeight: '500', fontFamily: 'Quicksand' }}>Note: </span>{formData.note}</Typography>


                    </Box>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography sx={{ fontSize: '22px', fontWeight: '500', marginBottom: '30px' }}>Gift Recipient Information</Typography>
                        <Typography sx={{ fontSize: '16px' }}><span style={{ fontWeight: '500' }}>Name: </span>{giftFormData.name}</Typography>
                        <Typography sx={{ fontSize: '16px' }}><span style={{ fontWeight: '500' }}>Phone: </span>{giftFormData.phone}</Typography>
                        <Typography sx={{ fontSize: '16px' }}><span style={{ fontWeight: '500' }}>Address: </span>{giftFormData.address}</Typography>
                        <Typography sx={{ fontSize: '16px' }}><span style={{ fontWeight: '500' }}>Message: </span>{giftFormData.message}</Typography>

                    </Box> */}
                </Grid>



                {/* products */}


                <Grid item xs={12} md={6}>

                    <Box sx={{ display: { md: 'flex', xs: 'none' }, flexDirection: 'column', alignItems: 'flex-start', marginBottom: '14px', color: '#021F3F' }}>
                        <Typography sx={{ fontSize: '22px', fontFamily: 'Quicksand' }}>Bags Total</Typography>


                    </Box>
                    <hr />

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '14px', color: '#021F3F' }}>

                        {/* column title */}

                        <Grid item container >
                            <Grid item xs={4} md={4} sx={{ color: '#021F3F' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'left', fontFamily: 'Quicksand' }}>Product Name</Typography>
                            </Grid>
                            <Grid item xs={4} md={4} >
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'Quicksand' }}>Qty</Typography>
                            </Grid>
                            <Grid item xs={4} md={4} sx={{ textAlign: 'right' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'Quicksand' }}>Amount</Typography>
                            </Grid>
                        </Grid>

                        {/* column entries */}
                        <Box sx={{ overflowY: 'scroll', float: 'left', height: '100px', width: '100%' }}>
                            {

                                carts.map(cart => <Grid key={cart.product_id} item container >
                                    <Grid item xs={4} md={4} sx={{ color: '#021F3F' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                            <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Quicksand' }}>{cart?.product_name}</Typography>
                                            <Box>
                                                {
                                                    cart?.select.map(c => <Box sx={{ display: 'flex', marginRight: '8px', color: '#95989A', fontWeight: 400, fontFamily: 'Quicksand' }}><Typography>{c.title}:</Typography><Typography> {c.vTitle}</Typography></Box>)
                                                }
                                            </Box>

                                        </Box>

                                    </Grid>
                                    <Grid item xs={4} md={4} >

                                        <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>{cart.quantity}</Typography>

                                    </Grid>
                                    <Grid item xs={4} md={4} sx={{ textAlign: 'right' }}>

                                        {

                                            cart?.unit_discounted_price ?
                                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>
                                                    {shop?.currencies?.title}   {cart?.unit_discounted_price * cart.quantity}
                                                </Typography>
                                                :
                                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>
                                                    {shop?.currencies?.title}   {cart?.unit_selling_price * cart?.quantity}
                                                </Typography>

                                        }

                                    </Grid>
                                </Grid>
                                )}
                        </Box>

                    </Box>
                    <hr />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '14px', color: '#021F3F' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={4} md={4} sx={{ color: '#021F3F', textAlign: 'left' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>Sub-total</Typography>
                                {/* <Typography sx={{ fontSize: '14px' }}>Discount</Typography> */}
                                {/* <Typography sx={{ fontSize: '14px' }}>Vat</Typography> */}
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>Delivery Charge</Typography>
                            </Grid>
                            <Grid item xs={4} md={4} sx={{ color: '#021F3F' }}>

                            </Grid>

                            <Grid item xs={4} md={4} sx={{ color: '#021F3F', textAlign: 'right' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>
                                    {shop?.currencies?.title}  {total}
                                </Typography>
                                {/* <Typography sx={{ fontSize: '14px', marginLeft: '80px' }}>{totalSellingPrice - total}</Typography> */}
                                {/* <Typography sx={{ fontSize: '14px', marginLeft: '80px' }}>{carts[0]?.vat_percentage}%</Typography> */}
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>
                                    {shop?.currencies?.title}   {deliveryCharge?.delivery_fees}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Box>
                    <hr />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '20px', color: '#021F3F' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={4} md={4} sx={{ color: '#021F3F', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>Total Amount</Typography>

                            </Grid>
                            <Grid item xs={4} md={4} >


                            </Grid>
                            <Grid item xs={4} md={4} sx={{ color: '#021F3F', textAlign: 'right' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Quicksand' }}>{shop?.currencies?.title} {totalWithDelivery}</Typography>
                                {/* <Typography sx={{ fontSize: '14px', marginLeft: '80px' }}>{total + totalVat}</Typography> */}
                            </Grid>
                        </Grid>

                    </Box>

                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '15px', color: '#000000' }}> */}
                    {/* <Typography sx={{ fontSize: '16px' }}>Delivery Date Picking</Typography> */}


                    {!searchParams.get('status') && <FormControl required fullWidth error={selectedPayment === null}>
                        <InputLabel id="demo-simple-select-label" sx={{ fontFamily: 'Quicksand' }}>Payment Method</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={paymentMethod}
                            name='Payment Method'
                            label="Payment Method"
                            onChange={handleChange}
                            sx={{ fontFamily: 'Quicksand', borderRadius: '0px' }}
                        >
                            {/* <MenuItem sx={{ fontFamily: 'Quicksand' }} value="">
                                <em>Select an Option</em>
                            </MenuItem> */}
                            {
                                shopPaymentMethods.map((item) =>
                                    <MenuItem key={item.id} sx={{ fontFamily: 'Montserrat' }} value={item.id}>
                                        {item.title}
                                    </MenuItem>)
                            }
                        </Select>
                    </FormControl>}


                    {!searchParams.get('status') && <Grid container >
                        <Grid item xs={0} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                            <ProceedButton
                                disabled={disable}
                                onClick={handleConfirmOrder}
                                variant="contained"
                                type="submit"
                                sx={{ fontFamily: 'Quicksand' }}
                            >
                                Confirm Order
                            </ProceedButton>
                        </Grid>
                        <Grid item xs={0} md={2}></Grid>
                    </Grid>}
                </Grid >
            </Grid >
        </Container >
    );
};

export default BagInfoSecStepRetro;