import { Container, Typography, Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from '../../../InstanceAxios/InstanceAxios';
import Footer from './Footer';
import OrderForm from './OrderForm';
import Testimonials from './Testimonials';
import { MessengerChat } from "react-messenger-chat-plugin";

const HomeSolo = () => {
    const shop = useSelector((state) => state.product.shop);
    const [themeDetails, setThemeDetails] = useState({});
    const [product, setProduct] = useState({});
    const [itemCounter, setItemCounter] = useState(1);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const myRef = useRef(null);

    const executeScroll = () => myRef.current.scrollIntoView()

    const handleItemCounter = (data) => {
        if (data === '-') {
            if (itemCounter > 1) {
                setItemCounter(itemCounter - 1);
            }
        } else {
            if (itemCounter < product.stock[0].number_of_stocks) {
                setItemCounter(itemCounter + 1);
            }
        }

    }

    const getLink = (data = '') => {
        return data.slice(data.indexOf('src="') + 5, data.indexOf('title') - 2);
    };

    const getSoloThemeProduct = async (productId) => {
        try {
            const url = `/products/getSingleProductV3/${productId}`;
            const response = await axios.get(url);
            setProduct(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getSoloThemeDetails = async () => {
        try {
            const response = await axios.get(`/soloTheme/getSoloTheme/${shop.shop_id}`);
            setThemeDetails(response.data);
            getSoloThemeProduct(response.data.product_id);
        } catch (error) {
            console.log(error);
        }
    }

    const getShopPaymentMethods = async () => {
        try {
            const url = `/paymentMethod/getAllPaymentMethodsByShop/${shop.shop_id}`;
            const response = await axios.get(url);
            setPaymentMethods(
                response.data.filter((paymentMethod) => paymentMethod.id === 1)
            );
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (shop) {
            getSoloThemeDetails();
            getShopPaymentMethods();
        }
    }, [shop]);

    useEffect(() => {
        if (Object.keys(product).length > 0) {
            if (product.stock[0].number_of_stocks === 0) {
                setItemCounter(0);
            }
        }
    }, [product]);

    return (
        <Box>
            {shop?.facebook_page_id && <MessengerChat
                pageId={shop?.facebook_page_id}
                language="en_US"
                themeColor={"#F7634D"}
                bottomSpacing={10}
                loggedInGreeting=""
                loggedOutGreeting=""
                greetingDialogDisplay={"show"}
                debugMode={false}
                onMessengerShow={() => {
                }}
                onMessengerHide={() => {
                }}
                onMessengerDialogShow={() => {
                }}
                onMessengerDialogHide={() => {
                }}
                onMessengerMounted={() => {
                }}
                onMessengerLoad={() => {
                }}
            />}
            <Box
                sx={{
                    padding: '16px',
                }}
            >
                <img
                    src={shop.shop_image}
                    alt="banner"
                    height="40px"
                />
            </Box>
            {
                themeDetails?.banner_img &&
                <Box
                    sx={{
                        height: { xs: '154px', md: '546px' },
                    }}
                >
                    <img
                        src={themeDetails?.banner_img}
                        alt="banner"
                        height="100%"
                        width="100%"
                        style={{
                            objectFit: 'cover',
                        }}
                    />
                </Box>
            }
            <Box
                sx={{
                    margin: { xs: '32px 0', md: '64px 0' },
                }}
            >
                <Typography
                    sx={{
                        mb: '8px',
                        color: '#D1D1D1',
                        fontSize: { xs: '12px', md: '16px' },
                    }}
                    style={{
                        fontFamily: 'Belleza',
                    }}
                >
                    {themeDetails?.pre_tagline}
                </Typography>
                <Typography
                    sx={{
                        mb: '24px',
                        color: '#000000',
                        fontSize: { xs: '22px', md: '56px' },
                    }}
                    style={{
                        fontFamily: 'Belleza',
                    }}
                >
                    {themeDetails?.tagline}
                </Typography>
                <Typography
                    sx={{
                        mb: '24px',
                        color: '#79747E',
                        fontSize: { xs: '14px', md: '24px' },
                        padding: '0px 5%',
                        textAlign: { xs: 'center', md: 'center' }
                    }}
                    style={{
                        fontFamily: 'Belleza',
                    }}
                >
                    {themeDetails?.tagline_description}
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        width: { xs: '116px', md: '116px' },
                        height: { xs: '40px', md: '40px' },
                        backgroundColor: '#000000',
                        color: '#FFFFFF',
                        fontSize: '16px',
                        padding: { xs: '0', md: '0' },
                        borderRadius: '0px',
                        '&:hover': {
                            backgroundColor: '#000000',
                        },
                        textTransform: 'none',
                    }}
                    style={{
                        fontFamily: 'Belleza',
                    }}
                    onClick={executeScroll}
                >
                    Shop Now
                </Button>
            </Box>
            <Box>
                <Typography
                    sx={{
                        mb: '8px',
                        color: '#D1D1D1',
                        fontSize: { xs: '12px', md: '16px' },
                    }}
                    style={{
                        fontFamily: 'Belleza',
                    }}
                >
                    {themeDetails?.product_one_liner}
                </Typography>
                <Typography
                    sx={{
                        mb: '24px',
                        color: '#000000',
                        fontSize: { xs: '22px', md: '56px' },
                    }}
                    style={{
                        fontFamily: 'Belleza',
                    }}
                >
                    {themeDetails?.feature_title}
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateAreas: {
                            xs: `"middle" "left" "right"`,
                            md: `"left middle right "`,
                        },
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0 5% 64px',
                    }}
                >
                    <Box sx={{ gridArea: 'left', mt: { xs: '32px', md: '0' } }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column-reverse', md: 'row' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: { xs: 'center', md: 'right' },
                                mb: { xs: '16px', md: '56px' },
                                mt: { xs: '16px', md: '0px' },
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        mt: { xs: '8px', md: '0' },
                                        mb: '8px',
                                        fontSize: { xs: '18px', md: '22px' },
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_one_title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { xs: '14px', md: '16px' },
                                        color: '#95989A'
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_one_description}
                                </Typography>
                            </Box>
                            {
                                themeDetails?.feature_one_icon &&
                                <Box
                                    sx={{
                                        padding: '0px 32px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: "#79747E",
                                            border: '3px solid #79747E',
                                            borderRadius: '50%',
                                            padding: { xs: '10px', md: '13px' },
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={themeDetails?.feature_one_icon}
                                            alt="banner"
                                            sx={{
                                                objectFit: 'contain',
                                                height: { xs: '24px', md: '40px' },
                                                width: { xs: '24px', md: '40px' },
                                            }}
                                        ></Box>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column-reverse', md: 'row' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: { xs: 'center', md: 'right' },
                                mb: { xs: '16px', md: '56px' },
                                mt: { xs: '16px', md: '0px' },
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        mt: { xs: '8px', md: '0' },
                                        mb: '8px',
                                        fontSize: { xs: '18px', md: '22px' },
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_two_title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { xs: '14px', md: '16px' },
                                        color: '#95989A'
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_two_description}
                                </Typography>
                            </Box>
                            {
                                themeDetails?.feature_two_icon &&
                                <Box
                                    sx={{
                                        padding: '0px 32px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: "#79747E",
                                            border: '3px solid #79747E',
                                            borderRadius: '50%',
                                            padding: { xs: '10px', md: '13px' },
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={themeDetails?.feature_two_icon}
                                            alt="banner"
                                            sx={{
                                                objectFit: 'contain',
                                                height: { xs: '24px', md: '40px' },
                                                width: { xs: '24px', md: '40px' },
                                            }}
                                        ></Box>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                    {
                        themeDetails?.featured_img &&
                        <Box sx={{
                            gridArea: 'middle',
                        }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        height: { xs: '250px', md: '397px' },
                                        width: { xs: '160px', md: '256px' },
                                        objectFit: 'contain',
                                    }}
                                    src={themeDetails?.featured_img}
                                    alt="featured"
                                >
                                </Box>
                            </Box>
                        </Box>
                    }
                    <Box sx={{ gridArea: 'right' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: { xs: 'center', md: 'left' },
                                mb: { xs: '16px', md: '56px' },
                                mt: { xs: '16px', md: '0px' },
                            }}
                        >
                            {
                                themeDetails?.feature_three_icon &&
                                <Box
                                    sx={{
                                        padding: '0px 32px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: "#79747E",
                                            border: '3px solid #79747E',
                                            borderRadius: '50%',
                                            padding: { xs: '10px', md: '13px' },
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={themeDetails?.feature_three_icon}
                                            alt="banner"
                                            sx={{
                                                objectFit: 'contain',
                                                height: { xs: '24px', md: '40px' },
                                                width: { xs: '24px', md: '40px' },
                                            }}
                                        ></Box>
                                    </Box>
                                </Box>
                            }
                            <Box>
                                <Typography
                                    sx={{
                                        mt: { xs: '8px', md: '0' },
                                        mb: '8px',
                                        fontSize: { xs: '18px', md: '22px' },
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_three_title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { xs: '14px', md: '16px' },
                                        color: '#95989A'
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_three_description}
                                </Typography>
                            </Box>

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: { xs: 'center', md: 'left' },
                                mb: { xs: '16px', md: '56px' },
                                mt: { xs: '16px', md: '0px' },
                            }}
                        >
                            {
                                themeDetails?.feature_four_icon &&
                                <Box
                                    sx={{
                                        padding: '0px 32px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: "#79747E",
                                            border: '3px solid #79747E',
                                            borderRadius: '50%',
                                            padding: { xs: '10px', md: '13px' },
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={themeDetails?.feature_four_icon}
                                            alt="banner"
                                            sx={{
                                                objectFit: 'contain',
                                                height: { xs: '24px', md: '40px' },
                                                width: { xs: '24px', md: '40px' },
                                            }}
                                        ></Box>
                                    </Box>
                                </Box>
                            }
                            <Box>
                                <Typography
                                    sx={{
                                        mb: '8px',
                                        fontSize: { xs: '18px', md: '22px' },
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_four_title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { xs: '14px', md: '16px' },
                                        color: '#95989A'
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {themeDetails?.feature_four_description}
                                </Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
            {themeDetails?.embedded_content_link &&
                <Box
                    sx={{
                        height: { xs: '250px', md: '703px' },
                    }}
                >
                    <iframe
                        width="100%"
                        height="100%"
                        src={getLink(themeDetails?.embedded_content_link)}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    >
                    </iframe>
                </Box>
            }
            <Box
                ref={myRef}
            >
                <OrderForm
                    themeDetails={themeDetails}
                    product={product}
                    itemCounter={itemCounter}
                    handleItemCounter={handleItemCounter}
                    paymentMethods={paymentMethods}
                />
            </Box>
            {
                themeDetails?.id &&
                <Testimonials
                    solo_theme_product_id={themeDetails?.id}
                />
            }
            <Footer />
        </Box>
    );
};

export default HomeSolo;