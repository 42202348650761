import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@mui/material';
import axios from '../../../../InstanceAxios/InstanceAxios'
import CardResponsiveRetro from '../../SharedRetro/Card/CardResponsiveRetro/CardResponsiveRetro';
import CardRetro from '../../SharedRetro/Card/CardRetro/CardRetro';


const useStyles = makeStyles({
    bigText: {

        color: '#000000',
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: '40px',
        textAlign: 'left',
        marginBottom: '28px'


    },
    bigTextRes: {

        color: '#000000',
        fontWeight: 500,
        fontSize: '5vw',
        // lineHeight: '40px',
        textAlign: 'left',
        marginBottom: '28px'


    },
    row: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: '390px',
        width: '100%'
    }

});

const YouMayAlsoLikeRetro = ({ shopId, categoryId }) => {

    const classes = useStyles();


    const [similarProduct, setSimilarProduct] = useState({});
    const url = `/productCategories/getAllproducts?category_id=${categoryId}&shop_id=${shopId}`
    // console.log(url);

    useEffect(() => {

        const fetchData = async () => {

            const response = await axios.get(url)
            setSimilarProduct(response.data)

        }
        fetchData()

    }, [categoryId])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    const products = similarProduct?.CategoryWitProducts?.products?.slice(0, 5)
    // console.log(products);
    return (
        <div>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={classes.bigText}>You may also like</p>

                <Grid container spacing={4} >

                    {
                        products?.map(product => <Grid key={product.product_id} item xs={6} sm={6} md={3}><CardRetro product={product}></CardRetro></Grid>)
                    }

                </Grid>
            </Box>

            <Box sx={{ display: { md: 'none' } }}>



                <p className={classes.bigTextRes}>You may also like</p>
                <Grid container spacing={2}>

                    {
                        products?.map(product => <Grid key={product.product_id} item xs={6}><CardResponsiveRetro product={product}></CardResponsiveRetro></Grid>)
                    }

                </Grid>
            </Box>

        </div>
    );
};

export default YouMayAlsoLikeRetro;