import { Box, Container } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../Shared/Footer/Footer';
import Navigation from '../Shared/Navigation/Navigation';
import axios from '../../../InstanceAxios/InstanceAxios'

const AboutUs = () => {
    const [shopData, setShopData] = React.useState([]);
    const shop = useSelector((state) => state.product.shop);

    const getShopData = async () => {
        try {
            const response = await axios.get(`/merchants/getShopData/${shop?.shop_link}`);
            setShopData(response.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getShopData();
    }, [shop?.shop_link]);

    return (
        <>
            <Navigation />
            <Container>
                <Box
                    style={{
                        minHeight: '40vh',
                        textAlign: 'justify',
                        marginTop: '100px',
                    }}
                    dangerouslySetInnerHTML={{ __html: shopData?.about_us }}
                ></Box>
            </Container>
            <Footer />
        </>
    );
};

export default AboutUs;