import { Box, Typography } from '@mui/material';
import React from 'react';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const Testimonial = ({ testimonial }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <Box
                component="img"
                sx={{
                    borderRadius: '50%',
                    height: { xs: '64px', md: '80px' },
                    width: { xs: '64px', md: '80px' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                }}
                src={testimonial.customer_photo}
            >
            </Box>
            <Typography
                sx={{
                    fontSize: { xs: '14px', md: '20px' },
                    color: '#000000',
                    margin: { xs: '16px 0 8px 0', md: '24px 0 4px 0' },
                    padding: '0 5%',
                }}
                style={{
                    fontFamily: 'Belleza',
                }}
            >
                {testimonial.testimonial_text}
            </Typography>
            <Typography
                sx={{
                    fontSize: { xs: '12px', md: '12px' },
                    color: '#79747E',
                    mb: { xs: '16px', md: '32px' }
                }}
                style={{
                    fontFamily: 'Belleza',
                }}
            >
                {testimonial.customer_name}
            </Typography>
            <Box>
                {
                    [...Array(parseInt(Number(testimonial.rating)))].map((star, i) => (
                        <StarRateIcon key={i} sx={{ color: '#FFC107', mr: '4px' }} />
                    ))
                }
                {
                    [...Array(5 - parseInt(Number(testimonial.rating)))].map((star, i) => (
                        <StarBorderIcon key={i} sx={{ color: '#FFC107', mr: '4px' }} />
                    ))
                }
            </Box>
        </Box>
    );
};

export default Testimonial;