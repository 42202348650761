import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../../InstanceAxios/InstanceAxios';
import {
    Box, Container, FormControl, Grid, InputLabel, MenuItem,
    Select, Typography, Backdrop,
    CircularProgress, TextField, Button, Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const OrderForm = ({ themeDetails, product, itemCounter, handleItemCounter, paymentMethods }) => {
    const shop = useSelector((state) => state.product.shop);
    const [values, setValues] = useState({
        customer_name: '',
        customer_phone_number: '',
        customer_address: '',
        payment_method_id: '',
    });
    const [isDisabled, setIsDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleChange = e => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    }

    const handleSubmit = async () => {
        try {
            setOpen(true);
            const formData = {};
            formData.items = {
                product_id: themeDetails?.product_id,
                product_name: product?.product_name,
                quantity: itemCounter,
                unit_selling_price: product?.unit_selling_price,
            }
            formData.shop_id = shop?.shop_id;
            formData.customer_name = values.customer_name;
            formData.customer_phone_number = values.customer_phone_number;
            formData.customer_address = values.customer_address;
            formData.payment_method_id = values.payment_method_id;
            formData.total_amount =
                (Number(product?.unit_selling_price) * Number(itemCounter))
                + Number(themeDetails?.delivery_charge || 0);
            formData.received_amount = 0;
            formData.delivery_charge = themeDetails?.delivery_charge || 0;
            const url = '/soloTheme/addSale';
            const response = await axios.post(url, formData);
            if (response.data.status === 'S103') {
                navigate(`/${shop?.shop_link}/successful`);
            } else {
                navigate(`/${shop?.shop_link}/failed`);
            }
        } catch (error) {
            console.log(error);
            navigate(`/${shop?.shop_link}/failed`);
        }
    }

    useEffect(() => {
        if (values.customer_name && values.customer_phone_number && values.customer_address && values.payment_method_id) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [values]);

    return (
        <Box
            sx={{
                padding: { xs: '32px 0', md: '64px 14%' },
            }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography
                sx={{
                    mb: '64px',
                    fontSize: { xs: '16px', md: '56px' },
                    textAlign: 'center',
                }}
                style={{
                    fontFamily: 'Belleza',
                }}
            >
                Order Form
            </Typography>
            <Grid container>
                <Grid
                    item
                    xs={12} md={6}
                    sx={{
                        pr: { xs: '0px', md: '75px' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: { xs: 'space-around', md: 'flex-start' },
                            alignItems: 'center',
                            textAlign: 'left',
                            padding: '0 5%',
                        }}
                    >
                        <Box>
                            <Box
                                component="img"
                                sx={{
                                    height: 128,
                                    width: 128,
                                    maxHeight: { xs: 90, md: 128 },
                                    maxWidth: { xs: 90, md: 128 },
                                    objectFit: 'contain',
                                }}
                                alt="banner"
                                src={themeDetails?.featured_img}
                            />
                        </Box>
                        <Box
                            sx={{
                                ml: { xs: '0px', md: '16px' },
                            }}
                        >
                            <Typography
                                sx={{
                                    mb: '4px',
                                    fontSize: '16px',
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                {product?.product_name}
                            </Typography>
                            <Typography
                                sx={{
                                    mb: '4px',
                                    fontSize: '14px',
                                    color: '#79747E'
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                {(product && product.stock?.length) && product?.stock[0]?.number_of_stocks} piece(s) in stock
                            </Typography>
                            <Typography
                                sx={{
                                    mb: '16px',
                                    fontSize: '16px',
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                {shop?.currencies?.title} {product?.unit_selling_price}
                            </Typography>
                            <Box
                                sx={{
                                    mt: { xs: '16px', md: '0px' },
                                }}
                            >
                                <Box
                                    sx={{
                                        height: '32px',
                                        border: '1px solid black',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        fontSize: '14px',
                                        color: 'black',
                                        maxWidth: { xs: '100px', md: '100px' },
                                        margin: { xs: '0 auto', md: '0' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: 'black',
                                            cursor: 'pointer',
                                            padding: { xs: '8px', md: '5px 12px' }
                                        }}
                                        onClick={() => handleItemCounter('-')}
                                    >-</Box>
                                    <Typography
                                        style={{
                                            fontFamily: 'Belleza',
                                        }}
                                    >
                                        {itemCounter}
                                    </Typography>
                                    <Box
                                        sx={{
                                            color: 'black',
                                            cursor: 'pointer',
                                            padding: { xs: '8px', md: '5px 12px' }
                                        }}
                                        onClick={() => handleItemCounter('+')}
                                    >+</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            margin: { xs: '32px 5% 16px 5%', md: '32px 5% 32px 5%' },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: '14px', md: '18px' },
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                Product Name
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        mr: { xs: '35px', md: '80px' },
                                        fontSize: { xs: '14px', md: '18px' },
                                        width: { xs: '60px', md: '70px' },
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    Quantity
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { xs: '14px', md: '18px' },
                                        width: { xs: '60px', md: '70px' },
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    Amount
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '24px 0 8px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                    maxWidth: { xs: '80px', md: '100px' },
                                    textAlign: 'left',
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                {product.product_name}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        mr: { xs: '35px', md: '80px' },
                                        fontSize: { xs: '14px', md: '16px' },
                                        width: { xs: '60px', md: '70px' },
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {itemCounter}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { xs: '12px', md: '16px' },
                                        width: { xs: '60px', md: '70px' },
                                        textAlign: 'right'
                                    }}
                                    style={{
                                        fontFamily: 'Belleza',
                                    }}
                                >
                                    {shop?.currencies?.title}  {itemCounter * product.unit_selling_price}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '8px 0'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                Sub-total
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                {shop?.currencies?.title} {itemCounter * product.unit_selling_price}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '8px 0'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                Delivery Charge
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                {shop?.currencies?.title} {themeDetails?.delivery_charge || 0}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '8px 0'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                Total Amount
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                }}
                                style={{
                                    fontFamily: 'Belleza',
                                }}
                            >
                                {shop?.currencies?.title} {(itemCounter * product.unit_selling_price) + Number(themeDetails?.delivery_charge || 0)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12} md={6}
                    sx={{
                        pl: { xs: '0px', md: '75px' },
                    }}
                >
                    <Box
                        sx={{
                            margin: { xs: '16px 5% 0 5%', md: '0' },
                        }}
                    >
                        <TextField
                            inputProps={{
                                style: {
                                    padding: '12.5px 14px',
                                }
                            }}
                            sx={{
                                margin: "0 0 12px 0",
                                '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                                    mt: '-3px',
                                }
                            }}
                            onChange={(e) => handleChange(e)}
                            name='customer_name'
                            value={values.customer_name}
                            id="outlined-basic"
                            label="Name*"
                            placeholder='Enter your name'
                            variant="outlined"
                            fullWidth />
                        <TextField
                            inputProps={{
                                style: {
                                    padding: '12.5px 14px',
                                }
                            }}
                            sx={{
                                margin: "12px 0 12px 0",
                                '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                                    mt: '-3px',
                                }
                            }}
                            onChange={(e) => handleChange(e)}
                            name='customer_phone_number'
                            value={values.customer_phone_number}
                            id="outlined-basic"
                            label="Phone*"
                            placeholder='Enter your phone number'
                            variant="outlined"
                            fullWidth />
                        <TextField
                            inputProps={{
                                style: {
                                    padding: '12.5px 14px',
                                }
                            }}
                            sx={{
                                margin: "12px 0 12px 0",
                                '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                                    mt: '-3px',
                                }
                            }}
                            onChange={(e) => handleChange(e)}
                            name='customer_address'
                            value={values.customer_address}
                            id="outlined-basic"
                            label="Address*"
                            placeholder='Enter your full address here'
                            variant="outlined"
                            fullWidth />
                        <Box
                            sx={{
                                width: '100%',
                                margin: '12px 0',
                                textAlign: 'left'
                            }}
                        >
                            <FormControl
                                fullWidth
                            >
                                <InputLabel
                                    id="demo-simple-select-label"
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        mt: '-3px',
                                    }}
                                >
                                    Payment Method
                                </InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.payment_method_id}
                                    name='payment_method_id'
                                    label="Payment Method"
                                    onChange={handleChange}
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        borderRadius: '0px',
                                        '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                            padding: '12.5px 14px',
                                        },
                                    }}
                                    fullWidth
                                >
                                    {
                                        paymentMethods.map((item) =>
                                            <MenuItem key={item.id} sx={{ fontFamily: 'Montserrat' }} value={item.id}>
                                                {item.title}
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Button
                            sx={{
                                fontSize: { xs: '18px', md: '16px' },
                                margin: { xs: '16px 0', md: '32px 0 0 0' },
                                height: '48 px',
                                width: '100%',
                                backgroundColor: 'black',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'black',
                                },
                                '&:disabled': {
                                    backgroundColor: '#95989A',
                                    color: 'white'
                                }
                            }}
                            style={{
                                fontFamily: 'Belleza',
                            }}
                            disabled={isDisabled}
                            onClick={handleSubmit}
                        >
                            Place Order
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
};

export default OrderForm;