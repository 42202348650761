import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CardResponsiveRetro from '../../SharedRetro/Card/CardResponsiveRetro/CardResponsiveRetro';
import CardRetro from '../../SharedRetro/Card/CardRetro/CardRetro';
import { makeStyles } from '@material-ui/styles';
import axios from '../../../../InstanceAxios/InstanceAxios'

const useStyles = makeStyles({
    bigText: {

        color: '#021F3F',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '40px',
        textAlign: 'left',
        marginBottom: '48px',
        marginTop: '80px'


    },
    bigTextRes: {

        color: '#021F3F',
        fontWeight: 500,
        fontSize: '5vw',
        // lineHeight: '40px',
        textAlign: 'left',
        marginBottom: '20px',
        marginTop: '20px'


    },
});

const AllProductsRetro = () => {
    const [products, setProducts] = useState([])
    const classes = useStyles();
    let shop

    shop = useSelector((state) => state?.product?.shop)
    const url = `/products/getProductsV3/${shop?.shop_id}?source=webStore`
    useEffect(() => {

        const fetchData = async () => {
            const response = await axios.get(url)
            setProducts(response.data?.data)
        }
        fetchData()

    }, [shop?.shop_id])

    console.log(products);
    return (
        <Container sx={{ marginBottom: '48px', marginTop: '64px' }}>

            {/* <p className={classes.bigText}>All Products</p> */}


            <Grid container spacing={{ xs: 3, md: 3 }} sx={{ display: { xs: 'none', md: 'flex' } }}>
                {
                    products?.map(product => <Grid key={product.product_id} item xs={6} sm={6} md={2.4}><CardRetro product={product}></CardRetro></Grid>)
                }

            </Grid>

            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between' }}>
                <p className={classes.bigTextRes}>All Products</p>

            </Box>
            <Grid container spacing={{ xs: 2, md: 3 }} sx={{ display: { md: 'none' } }}>

                {
                    products?.map(product => <Grid key={product.product_id} item xs={6}><CardResponsiveRetro product={product}></CardResponsiveRetro></Grid>)
                }

            </Grid>

        </Container>
    );
};

export default AllProductsRetro;