import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../InstanceAxios/InstanceAxios'


export const fetchShop = createAsyncThunk(
    'shop/fetchShop',
    async (shopLink) => {

        const response = await axios.get(`/merchants/shopV3/${shopLink}`)
        // console.log(response.data);

        return response.data
    }
)


export const fetchProducts = createAsyncThunk(
    'product/fetchProducts',
    async (shopId) => {
        // console.log('clicked');
        const response = await axios.get(`products/getProductsV2/${shopId}?source=webStore`)

        return response.data
    }
)

const productSlice = createSlice({
    name: 'products',
    initialState: {
        discover: [],
        shop: []

    },

    reducers: {

    },
    extraReducers: (builder) => {

        builder.addCase(fetchShop.fulfilled, (state, action) => {

            state.shop = action.payload;
            action.payload ? localStorage.setItem('shop', JSON.stringify(action.payload)) : '';
        })



        builder.addCase(fetchProducts.fulfilled, (state, action) => {

            state.discover = action.payload
        })

        // builder.addCase(fetchProductById.fulfilled, (state, action) => {

        //     state.newState = action.payload
        // })
    },
})



export default productSlice.reducer;