import React from 'react';
import { Box } from '@mui/system';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@mui/material';
import ShopNowButtonRetro from '../../Buttons/ShopNowButtonRetro/ShopNowButtonRetro';



const useStyles = makeStyles({
    cardStyle: {

        maxHeight: '569px',
        maxWidth: '376px',
        width: 'auto',
        height: 'auto',
        aspectRatio: '1/1',
        // border: '1px solid #E6E6E6',
        // paddingRight: '16px',
        // paddingLeft: '16px',
        // paddingTop: '16px',
        paddingBottom: '24px',
        textAlign: 'center',

    },
    img: {

        objectFit: 'cover',
        width: '100%',
        height: '100%',

    },

    cardContent: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '1.042vw',
        fontFamily: 'Quicksand'

    },
    price: {
        fontWeight: 500,
        fontSize: '16px',
        display: 'inline-block',
        // lineHeight: '24px',
        marginRight: '8px',
        marginBottom: '0px',

    },
    outOfStock: {
        backgroundColor: '#000000',
        position: 'absolute',
        paddingY: '3px',
        paddingX: '4px',
        border: '1px solid #FFFFFF',
        fontFamily: 'Quicksand',
        color: '#FFFFFF',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        marginTop: '16px',


    },
    

});

const CardRetro = ({ product }) => {

    const classes = useStyles();
    const navigate = useNavigate();

    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;

    let shop = useSelector((state) => state?.product?.shop)


    // shop = JSON.parse(localStorage.getItem('shop'));
    // console.log(product);
    let content;

    if (product.percentage_off) {
        content = <><p className={classes.price} style={{ color: '#000000' }}>
            {shop?.currencies?.title} {product.unit_discounted_price}</p>
            <del style={{ color: ' #95989A', marginRight: '7px', fontSize: '14px' }}>
               {shop?.currencies?.title} {product.unit_selling_price}</del>
            <p style={{ color: '#00DD64', display: 'inline-block' }} className={classes.cardContent}>{product.percentage_off}% off</p>
            {/* <Box sx={{ display: 'flex' }}> */}

            {/* </Box> */}
        </>
    }


    else if (product.amount_off) {
        content = <><p className={classes.price} style={{ color: '#000000' }}>
            {shop?.currencies?.title} {product.unit_discounted_price}</p>

            <del style={{ color: ' #95989A', marginRight: '4px', fontSize: '14px' }}>
                {shop?.currencies?.title} {product.unit_selling_price}</del>
            <p style={{ color: '#00DD64', display: 'inline-block' }} className={classes.cardContent}>
                {shop?.currencies?.title} {product.amount_off} off</p>
        </>
    }

    else {
        content = <><p className={classes.price} style={{ color: '#000000', marginBottom: '14px' }}>
            {shop?.currencies?.title} {product.unit_selling_price}</p>
        </>
    }


    let contentOutOfStock;
    if (product?.stock[0]?.variant_stocks === null) {
        if (product?.stock[0]?.number_of_stocks < 1) {
            contentOutOfStock = 'Out of Stock'
        }
    }
    else {
        if (product?.stock[0]?.variant_stocks < 1) {
            contentOutOfStock = 'Out of Stock'
        }
    }


    const handleClick = () => {
        // console.log(product?.product_id);
        hostName == name ? navigate(`/${shop?.shop_link}/products/${product?.product_id}`) : navigate(`/products/${product?.product_id}`)
    }



    return (
        <Box onClick={handleClick} className={classes.cardStyle}>
            {
                contentOutOfStock && <Typography className={classes.outOfStock}>{contentOutOfStock}</Typography>

            }

            <Box sx={{ width: '100%', aspectRatio: '1/1' }}>
                {
                    product?.product_images[1] ?
                        <img className={classes.img} src={product?.product_images[0]?.image_url}
                            onMouseOver={e => (e.currentTarget.src = product?.product_images[1]?.image_url)}
                            onMouseOut={e => (e.currentTarget.src = product?.product_images[0]?.image_url)}
                        />
                        :
                        <img className={classes.img} src={product?.product_images[0]?.image_url} />
                    //product?.product_images.map(image => <img className={classes.img} src={image?.image_url} />)
                }
            </Box>
            <Typography style={{ textAlign: 'left', marginBottom: '8px', marginTop: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#000000', fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px' }} className={classes.cardContent}>{product.product_name}</Typography>
            <Box sx={{ textAlign: 'left' }}>{content}</Box>

            <ShopNowButtonRetro product_id={product?.product_id}></ShopNowButtonRetro>


        </Box>
    );
};

export default CardRetro;