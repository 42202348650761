import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Grid, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import BagInfoSecStepTechne from '../BagInfoSecStepTechne/BagInfoSecStepTechne';
import CustomerInfoTechne from '../CustomerInfoTechne/CustomerInfoTechne';
import NavBarTechne from '../../SharedTechne/NavBarTechne/NavBarTechne';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../../../InstanceAxios/InstanceAxios';
import { useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";


const steps = ['Customer Details', 'Order & Payment'];

const BagInfoTechne = () => {
    const dispatch = useDispatch();
    const activeStep = 0
    const [completed, setCompleted] = useState([]);

    const navigate = useNavigate()

    // ------Toggle Button----
    const [checked, setChecked] = useState(false);

    const shop = useSelector((state) => state?.product?.shop)
    const cart = useSelector((state) => state?.cart)
    const cartItems = cart?.cartItems;
    const carts = cartItems?.filter(cartItem => cartItem?.shop_id === shop?.shop_id)

    const items = carts.map(cart => {
        // console.log(cart);
        return {
            campaign_id: cart.campaign_id,

            product_id: cart.product_id,

            product_name: cart.product_name,

            quantity: cart.quantity,

            unit_selling_price: cart.unit_selling_price
        }
    }
    )

    const name = process.env.React_App_NAME;

    const hostName = window.location.hostname;





    const handleSubmit = async (e) => {


        e.preventDefault();
        hostName == name ? navigate(`/${shop?.shop_link}/invoice`) : navigate('/invoice')



    }



    const totalSteps = () => {
        return steps?.length;
    };

    const completedSteps = () => {
        return Object.keys(completed)?.length;
    };



    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };





    const handleStep = (step) => () => {
        setActiveStep(step);
    };



    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };



    // ------Toggle Button---
    const handleChange = (event) => {
        setChecked(event.target.checked);
    }


    const ProceedButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        width: '25%',
        height: '56px',

        marginBottom: '80px',
        marginTop: '60px',
        fontSize: '22px',
        fontWeight: 'normal',
        backgroundColor: '#000000',
        borderColor: '#000000',
        fontFamily: 'Quicksand',
        '&:hover': {
            backgroundColor: '#000000',
        },
        [theme.breakpoints.down('md')]: {

            width: '100%',
        },
    }));

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    // console.log(deliveryCharge);

    if (shop?.facebook_pixel_id) {
        ReactPixel.pageView();
    }
    if (shop?.google_analytics_measurement_id) {
        ReactGA.send("pageview");
    }

    return (
        <>
            <NavBarTechne />
            <Box sx={{ width: '100%' }} display={{ xs: 'none', md: 'block' }}>

                <Stepper nonLinear activeStep={activeStep} sx={{ width: '40%', marginX: '450px', marginTop: '100px', marginBottom: '76px', fontFamily: 'Quicksand' }}>
                    {steps.map((label, index) => (
                        <Step sx={{ fontFamily: 'Quicksand' }} key={label} >
                            <StepButton sx={{ fontFamily: 'Quicksand' }} color="inherit">
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <div>
                    {allStepsCompleted() ? (
                        <Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>

                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, fontFamily: 'Quicksand' }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                            {/* <NavLink to='/successful'></NavLink> */}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {
                                activeStep ? (
                                    // -------------  step2  ---------
                                    // <form onSubmit={e => e.preventDefault()}>
                                    <Box>
                                        {/* <BagInfoSecStepTechne deliveryCharge={deliveryCharge} /> */}

                                    </Box>
                                    // </form>

                                    // -----------End-----------

                                ) : (
                                    <>

                                        {/* ---------------Customers Information------------- */}

                                        {/* ---------step 1------------ */}

                                        <form onSubmit={handleSubmit}>
                                            <CustomerInfoTechne />


                                            <ProceedButton variant="contained" type="submit">
                                                Proceed
                                            </ProceedButton>


                                        </form>


                                    </>
                                )
                            }


                        </Fragment>
                    )}
                </div>
            </Box >


            {/* responsive */}
            <Box sx={{ width: '100%' }} display={{ xs: 'block', md: 'none' }}>

                <Stepper nonLinear activeStep={activeStep} sx={{ width: '90%', marginX: '22px', marginTop: '25px', marginBottom: '25px' }}>
                    {steps.map((label, index) => (
                        <Step key={label} >
                            <StepButton color="inherit">
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <div>
                    {allStepsCompleted() ? (
                        <Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>

                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                            {/* <NavLink to='/successful'></NavLink> */}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {
                                activeStep ? (
                                    // -------------  step2  ---------

                                    <Box>
                                        {/* <BagInfoSecStepTechne deliveryCharge={deliveryCharge} /> */}

                                    </Box>
                                    // </form>

                                    // -----------End-----------

                                ) : (
                                    <>

                                        {/* ---------------Customers Information------------- */}

                                        {/* ---------step 1------------ */}

                                        <form onSubmit={handleSubmit}>
                                            <CustomerInfoTechne />
                                            <Container>

                                                <Grid container spacing={4} sx={{ display: { xs: 'flex', md: 'none' } }}>

                                                    <Grid item md={4} xs={12}>
                                                        <ProceedButton variant="contained" type="submit">
                                                            Proceed
                                                        </ProceedButton>
                                                    </Grid>


                                                </Grid>
                                            </Container>


                                        </form>


                                    </>
                                )
                            }


                        </Fragment>
                    )}
                </div>
            </Box >

        </>
    );
};

export default BagInfoTechne;