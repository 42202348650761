import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    bigText: {
        color: '#021F3F',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
    },
    bigTextRes: {
        color: '#021F3F',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left'
    },
    smText: {
        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '12px',
        textAlign: 'left',
        display: 'flex',
        cursor: 'pointer'
    },
    smTextRes: {
        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '0px',
        textAlign: 'left'
    },
    iconStyle: {
        width: '13.47px',
        color: '#79747E',
        marginRight: '10px'
    },
    socialMedia: {
        height: '30px',
        width: '30px',
        color: '#FFFFFF',
        paddingTop: '6px',
        paddingBottom: '6px',
        backgroundColor: '#D1D1D1',
        border: '1px solid #D1D1D1',
        borderRadius: '15px',
        marginRight: '12px',
    },
    poweredByLink: {
        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        paddingBottom: '48px',
        textDecoration: 'none',
        '&:hover': {
            color: '#FF6550'
        }
    }
});