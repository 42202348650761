import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from 'react-redux';
import { fetchShop } from './redux/slices/productSlice';
import { lazy, Suspense, useEffect, useState } from 'react';
import { getTotals } from './redux/slices/cartSlice';

import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const Successful = lazy(() => import('./Themes/Singularity/Bag/Successful/Successful'));
const BagInfo = lazy(() => import('./Themes/Singularity/Bag/BagInfo/BagInfo'))
const ProductDetails = lazy(() => import('./Themes/Singularity/ProductDetails/ProductDetails/ProductDetails'));
const NewArrivalPage = lazy(() => import('./Themes/Singularity/NewArrivalPage/NewArrivalPage/NewArrivalPage'));
const AllProductsPage = lazy(() => import('./Themes/Singularity/AllProductsPage/AllProductsPage/AllProductsPage'));
const CollectionsPage = lazy(() => import('./Themes/Singularity/CollectionsPage/CollectionsPage/CollectionsPage'));
const CategoryDetailsPage = lazy(() => import('./Themes/Singularity/CategoryDetailsPage/CategoryDetailsPage'));
const BagResponsive = lazy(() => import('./Themes/Singularity/Bag/BagResponsive/BagResponsive'));
const NotFoundPage = lazy(() => import('./Themes/Singularity/Shared/NotFoundPage/NotFoundPage'));
const Menu = lazy(() => import('./Themes/Singularity/Shared/Menu/Menu'));
const Home = lazy(() => import('./Themes/Singularity/Home/Home/Home'));


const HomeTechne = lazy(() => import('./Themes/Techne/HomeTechne/HomeTechne/HomeTechne.js'))
const ProductDetailsTechne = lazy(() => import('./Themes/Techne/ProductDetailsTechne/ProductDetailsTechne/ProductDetailsTechne'))

import NotFound from './images/logo/404.png';
import { CircularProgress } from '@mui/material';
import CollectionsPageTechne from './Themes/Techne/CollectionsPageTechne/CollectionsPageTechne/CollectionsPageTechne';
import CollectionDetailsPageTechne from './Themes/Techne/CollectionsPageTechne/CollectionDetailsPageTechne/CollectionDetailsPageTechne';
import BagResponSiveTechne from './Themes/Techne/BagTechne/BagResponsiveTechne/BagResponSiveTechne';
import BagInfoTechne from './Themes/Techne/BagTechne/BagInfoTechne/BagInfoTechne';
import MenuTechne from './Themes/Techne/SharedTechne/MenuTechne/MenuTechne';
import SuccessfulTechne from './Themes/Techne/BagTechne/SuccessfulTechne/SuccessfulTechne';
import BagInfoSecStep from './Themes/Singularity/Bag/BagInfoSecStep/BagInfoSecStep';
import BagInfoSecStepTechne from './Themes/Techne/BagTechne/BagInfoSecStepTechne/BagInfoSecStepTechne';
import Failed from './Themes/Singularity/Bag/Failed/Failed';
import Cancelled from './Themes/Singularity/Bag/Cancelled/Cancelled';
import CancelledTechne from './Themes/Techne/BagTechne/CancelledTechne/CancelledTechne';
import FailedTechne from './Themes/Techne/FailedTechne/FailedTechne';
import { RETRO, SINGULARITY, SOLO, TECHNE } from './Constants';

import HomeRetro from './Themes/Retro/HomeRetro/HomeRetro/HomeRetro';
import ProductDetailsRetro from './Themes/Retro/ProductDetailsRetro/ProductDetailsRetro/ProductDetailsRetro';
import CollectionsPageRetro from './Themes/Retro/CollectionsPageRetro/CollectionsPageRetro/CollectionsPageRetro';
import CollectionDetailsPageRetro from './Themes/Retro/CollectionsPageRetro/CollectionDetailsPageRetro/CollectionDetailsPageRetro';
import BagResponSiveRetro from './Themes/Retro/BagRetro/BagResponsiveRetro/BagResponSiveRetro';
import BagInfoRetro from './Themes/Retro/BagRetro/BagInfoRetro/BagInfoRetro';
import BagInfoSecStepRetro from './Themes/Retro/BagRetro/BagInfoSecStepRetro/BagInfoSecStepRetro';
import MenuRetro from './Themes/Retro/SharedRetro/MenuRetro/MenuRetro';
import SuccessfulRetro from './Themes/Retro/BagRetro/SuccessfulRetro/SuccessfulRetro';
import CancelledRetro from './Themes/Retro/BagRetro/CancelledRetro/CancelledRetro';
import FailedRetro from './Themes/Retro/BagRetro/FailedRetro/FailedRetro';
import HomeSolo from './Themes/Solo/HomeSolo/HomeSolo';
import SuccessfulSolo from './Themes/Solo/SuccessfulSolo/SuccessfulSolo';
import FailedSolo from './Themes/Solo/FailedSolo/FailedSolo';
import { Box } from '@mui/system';
import AboutUs from './Themes/Singularity/AboutUs/AboutUs';
import TermsAndConditions from './Themes/Singularity/TermsAndConditions/TermsAndConditions';
import { ThemeProvider } from '@mui/material/styles';
import { themeConfig } from './ThemeConfig';
import AboutUsTechne from './Themes/Techne/AboutUsTechne/AboutUsTechne';
import TermsAndConditionsAboutUsTechne from './Themes/Techne/TermsAndConditionsAboutUsTechne/TermsAndConditionsAboutUsTechne';
import AboutUsSolo from './Themes/Solo/AboutUsSolo/AboutUsSolo';
import TermsAndConditionsSolo from './Themes/Solo/TermsAndConditionsSolo/TermsAndConditionsSolo';
import AboutUsRetro from './Themes/Retro/AboutUsRetro/AboutUsRetro';
import TermsAndConditionsRetro from './Themes/Retro/TermsAndConditionsRetro/TermsAndConditionsRetro';
import WhatsappIcon from './images/logo/whatsapp_icon.svg';
import PopupModal from './utilities/PopupModal/PopupModal';

function App() {
  const hostName = window.location.hostname;
  const dispatch = useDispatch();
  const name = process.env.React_App_NAME;
  let shop = {}
  const path = window.location.pathname
  const shopLink = path.split("/");
  const [open, setOpen] = useState(true);

  useEffect(() => {
    dispatch(getTotals())
    hostName == name ? dispatch(fetchShop(shopLink[1])) : dispatch(fetchShop(hostName))

  }, [dispatch])

  shop = useSelector((state) => state.product.shop);
  if (shop == null) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box
          component="img"
          src={NotFound}
        >
        </Box>
        <Box
          component="h1"
          sx={{
            paddingTop: '2rem',
          }}
        >
          Page not found
        </Box>
      </Box>
    )
  }

  if (shop?.facebook_pixel_id) {
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(shop?.facebook_pixel_id, advancedMatching, options);
  }

  if (shop?.google_analytics_measurement_id) {
    ReactGA.initialize(shop?.google_analytics_measurement_id);
  }

  return (
    <ThemeProvider theme={themeConfig[shop?.theme_id]}>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{shop?.shop_name}</title>
          <meta name="name" content="0; URL=https://www.bonikapp.com/" />
          {/* <meta name={`//${shop.shop_name}`} content="0; URL=https://www.bonikapp.com/" /> */}
          <link rel="bonikApp" href="https://www.bonikapp.com/" />

        </Helmet>
        {
          shop?.whatsapp_number &&
          <Box
            sx={{
              width: '50px',
              height: '50px',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              bottom: '10px',
              left: { xs: '16px', md: '20px' },
              cursor: 'pointer',
              backgroundColor: '#25d366',
              borderRadius: '50%',
              userSelect: 'none',
              boxShadow: '0px 4px 10px 0px rgb(0 0 0 / 15%)',
              zIndex: '9998',
              fill: '#fff',
              padding: '0.5rem',
              '&:hover': {
                backgroundColor: '#109942',
              }
            }}
            onClick={() => {
              window.open(`https://api.whatsapp.com/send/?phone=${shop?.whatsapp_number}&text=Hi`, "_blank")
            }}

          >
            <svg focusable='false' viewBox='0 0 24 24' width='55' height='55'>
              <path d='M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z' />
            </svg>

          </Box>
        }

        {/* {
          (shop?.popup_show_status && open) &&
          <PopupModal
            shop={shop}
            open={open}
            setOpen={setOpen}
          />
        } */}

        <BrowserRouter>

          {

            shop?.theme_id === SINGULARITY &&
            <Suspense fallback={<CircularProgress
              sx={{ color: '#FF6650', marginTop: '30%' }}
              size={40}
              thickness={4} />}>
              <Routes>

                {
                  hostName !== name ? (shop?.domain_status != 'active' && <Route path="/notFound" element={<NotFoundPage />} />) : (shop === null && <Route path="/notFound" element={<NotFoundPage />} />)
                }


                {
                  hostName == name ? <Route path="/:shop_link" element={<Home />} /> : <Route path="/" element={<Home />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/bagInfo" element={<BagInfo />} /> : <Route path="/bagInfo" element={<BagInfo />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/bagInfo" element={<BagInfo />} /> : <Route path="/bagInfo" element={<BagInfo />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/invoice" element={<BagInfoSecStep />} /> : <Route path="/invoice" element={<BagInfoSecStep />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/newArrival" element={<NewArrivalPage />} /> : <Route path="/newArrival" element={<NewArrivalPage />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/allProducts" element={<AllProductsPage />} /> : <Route path="/allProducts" element={<AllProductsPage />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/collections" element={<CollectionsPage />} /> : <Route path="/collections" element={<CollectionsPage />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/bag" element={<BagResponsive />} /> : <Route path="/bag" element={<BagResponsive />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/menu" element={<Menu />} /> : <Route path="/menu" element={<Menu />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/successful" element={<Successful />} /> : <Route path="/successful" element={<Successful />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/failed" element={<Failed />} /> : <Route path="/failed" element={<Failed />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/cancelled" element={<Cancelled />} /> : <Route path="/cancelled" element={<Cancelled />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/products/:pid" element={<ProductDetails />} /> : <Route path="/products/:pid" element={<ProductDetails />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/categoryDetails/:cid" element={<CategoryDetailsPage />} /> : <Route path="/categoryDetails/:cid" element={<CategoryDetailsPage />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/aboutUs" element={<AboutUs />} /> : <Route path="/aboutUs" element={<AboutUs />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/termsAndConditions" element={<TermsAndConditions />} /> : <Route path="/termsAndConditions" element={<TermsAndConditions />} />
                }

              </Routes>
            </Suspense>
          }
          {
            shop?.theme_id === TECHNE &&
            <Suspense fallback={<CircularProgress
              sx={{ color: '#000000', marginTop: '30%' }}
              size={40}
              thickness={4} />}>
              <Routes>

                {
                  hostName !== name ? (shop?.domain_status != 'active' && <Route path="/notFound" element={<NotFoundPage />} />) : (shop === null && <Route path="/notFound" element={<NotFoundPage />} />)
                }
                {
                  hostName == name ? <Route path="/:shop_link" element={<HomeTechne />} /> : <Route path="/" element={<HomeTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/products/:pid" element={<ProductDetailsTechne />} /> : <Route path="/products/:pid" element={<ProductDetailsTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/collections" element={<CollectionsPageTechne />} /> : <Route path="/collections" element={<CollectionsPageTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/categoryDetails/:cid" element={<CollectionDetailsPageTechne />} /> : <Route path="/categoryDetails/:cid" element={<CollectionDetailsPageTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/bag" element={<BagResponSiveTechne />} /> : <Route path="/bag" element={<BagResponSiveTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/bagInfo" element={<BagInfoTechne />} /> : <Route path="/bagInfo" element={<BagInfoTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/invoice" element={<BagInfoSecStepTechne />} /> : <Route path="/invoice" element={<BagInfoSecStepTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/menu" element={<MenuTechne />} /> : <Route path="/menu" element={<MenuTechne />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/successful" element={<SuccessfulTechne />} /> : <Route path="/successful" element={<SuccessfulTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/cancelled" element={<CancelledTechne />} /> : <Route path="/cancelled" element={<CancelledTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/failed" element={<FailedTechne />} /> : <Route path="/failed" element={<FailedTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/aboutUsTechne" element={<AboutUsTechne />} /> : <Route path="/aboutUsTechne" element={<AboutUsTechne />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/termsAndConditionsTechne" element={<TermsAndConditionsAboutUsTechne />} /> : <Route path="/termsAndConditionsTechne" element={<TermsAndConditionsAboutUsTechne />} />
                }
              </Routes>
            </Suspense>
          }

          {
            shop?.theme_id === RETRO &&
            <Suspense fallback={<CircularProgress
              sx={{ color: '#000000', marginTop: '30%' }}
              size={40}
              thickness={4} />}>
              <Routes>

                {
                  hostName !== name ? (shop?.domain_status != 'active' && <Route path="/notFound" element={<NotFoundPage />} />) : (shop === null && <Route path="/notFound" element={<NotFoundPage />} />)
                }
                {
                  hostName == name ? <Route path="/:shop_link" element={<HomeRetro />} /> : <Route path="/" element={<HomeRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/products/:pid" element={<ProductDetailsRetro />} /> : <Route path="/products/:pid" element={<ProductDetailsRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/collections" element={<CollectionsPageRetro />} /> : <Route path="/collections" element={<CollectionsPageRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/categoryDetails/:cid" element={<CollectionDetailsPageRetro />} /> : <Route path="/categoryDetails/:cid" element={<CollectionDetailsPageRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/bag" element={<BagResponSiveRetro />} /> : <Route path="/bag" element={<BagResponSiveRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/bagInfo" element={<BagInfoRetro />} /> : <Route path="/bagInfo" element={<BagInfoRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/invoice" element={<BagInfoSecStepRetro />} /> : <Route path="/invoice" element={<BagInfoSecStepRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/menu" element={<MenuRetro />} /> : <Route path="/menu" element={<MenuRetro />} />
                }

                {
                  hostName == name ? <Route path="/:shop_link/successful" element={<SuccessfulRetro />} /> : <Route path="/successful" element={<SuccessfulRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/cancelled" element={<CancelledRetro />} /> : <Route path="/cancelled" element={<CancelledRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/failed" element={<FailedRetro />} /> : <Route path="/failed" element={<FailedRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/aboutUsRetro" element={<AboutUsRetro />} /> : <Route path="/aboutUsRetro" element={<AboutUsRetro />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/termsAndConditionsRetro" element={<TermsAndConditionsRetro />} /> : <Route path="/termsAndConditionsRetro" element={<TermsAndConditionsRetro />} />
                }
              </Routes>
            </Suspense>
          }
          {
            shop?.theme_id === SOLO &&
            <Suspense fallback={<CircularProgress
              sx={{ color: '#FF6650' }}
              size={40}
              thickness={4} />}>
              <Routes>
                {
                  hostName !== name ? (shop?.domain_status != 'active' && <Route path="/notFound" element={<NotFoundPage />} />) : (shop === null && <Route path="/notFound" element={<NotFoundPage />} />)
                }
                {
                  hostName == name ? <Route path="/:shop_link" element={<HomeSolo />} /> : <Route path="/" element={<HomeSolo />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/successful" element={<SuccessfulSolo />} /> : <Route path="/success" element={<SuccessSolo />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/failed" element={<FailedSolo />} /> : <Route path="/failed" element={<FailedSolo />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/aboutUsSolo" element={<AboutUsSolo />} /> : <Route path="/aboutUsSolo" element={<AboutUsSolo />} />
                }
                {
                  hostName == name ? <Route path="/:shop_link/termsAndConditionsSolo" element={<TermsAndConditionsSolo />} /> : <Route path="/termsAndConditionsSolo" element={<TermsAndConditionsSolo />} />
                }
              </Routes>
            </Suspense>
          }



        </BrowserRouter>
      </div >
    </ThemeProvider >
  );
}

export default App;
