import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from '../../../../InstanceAxios/InstanceAxios'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CheckoutForm from "./CheckoutForm";
import "./StripeCheckoutModal.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function StripeCheckoutModal({ isOpen, amount,stripePromise, shop_id }) {
    const [clientSecret, setClientSecret] = useState("");

    const createPaymentIntent = async () => {
        try {
            const response = await axios.post('/stripe/create-payment-intent',
                {
                    items: [{ id: "products" }],
                    amount: parseInt(amount),
                    shop_id: shop_id
                },
                { headers: { "Content-Type": "application/json" } }
            );
            setClientSecret(response.data.clientSecret);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        createPaymentIntent();
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <Dialog open={isOpen}>
                        <CheckoutForm />
                    </Dialog>
                </Elements>
            )}
        </>
    );
}