import { createTheme } from '@mui/material/styles';

const themeSingularity = createTheme({
    typography: {
        fontFamily: "Montserrat",
    }
});

const themeTechne = createTheme({
    typography: {
        fontFamily: "Quicksand",
    }
});

const themeRetro = createTheme({
    typography: {
        fontFamily: "Quicksand",
    }
});

const themeSolo = createTheme({
    typography: {
        fontFamily: "Belleza",
    }
});

export const themeConfig = {
    1: themeSingularity,
    2: themeTechne,
    3: themeRetro,
    4: themeSolo
}


